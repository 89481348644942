.starter-container
    .screen
        height: 65vh
        display: grid
        place-items: center

        h1
            font-weight: bold

        a
            text-decoration: none

        &-dark
            background: #212529
            text-align: right

