#main-container
    place-items: center

#sign-in-form
    max-width: 330px

    .link-primary
        text-decoration: none

.form-control
    &:focus
        z-index: 5

.logo
    width: 250px
