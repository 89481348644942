.dropdown-avatar
    // .dropdown-menu

    .dropdown-toggle
        // padding: 2px
        color: white
        &::after
            color: white

    hr
        width: 100%
        margin-top: 5px
        margin-bottom: 5px
        border: 1px solid #6c757d

    button
        background-color: #ffffff00
        border: none
