.group-schedule-container
    h1
        a
            color: #fff
            text-decoration: none

            &:hover
                color: #aaa
    
    .calendar
        &-number
            width: 45px
            height: 45px