.box-tasks
    a
        text-decoration: none

    .btn-secondary
        align-items: center
        justify-content: center
        height: 150px
        color: #fff
        border: none

    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active, .btn-secondary.active, .open>.dropdown-toggle.btn-secondary
        color: #fff
        opacity: 0.5