@import "./variables"
@import "~bootstrap/scss/bootstrap"


*
    // zoom: 98%
    // border: 1px solid cyan

body
    background-color: #1e1e1e
    min-height: 100vh
    display: grid

.text-align-left
    text-align: left

.text-align-right
    text-align: right

.centered-label
    vertical-align: text-top

.btn-warning
    color: #fff
    background-color: #e4ab01
    border-color: #e4ab01

.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active, .open>.dropdown-toggle.btn-warning
    color: #fff
    background-color: #c89600
    border-color: #c89600

.btn-warning:disabled, .btn-warning.disabled
    color: #fff

.btn-info
    color: #fff
    background-color: #00BFFF
    border-color: #00BFFF

.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active, .open>.dropdown-toggle.btn-info
    color: #fff
    background-color: #04a4da
    border-color: #04a4da

.btn-info:disabled, .btn-info.disabled
    color: #fff

.dropdown-toggle:after
    display: none !important


@media (max-width: 1200px)
    *
        zoom: 100%
        
@media (max-width: 992px)
    *
        zoom: 100%

@media (max-width: 768px)
    *
        zoom: 100%

@media (max-width: 576px)
    *
        zoom: 98%
    